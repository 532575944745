.links-page {
  padding: 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
}

.links-title {
  font-size: 36px;
  color: #4b2e83; /* UW Purple */
  text-align: center;
  margin-bottom: 30px;
}

.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.link-button {
  background-color: #4b2e83; /* UW Purple */
  color: #ffffff; /* White text */
  padding: 15px 20px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 500px; /* Adjust as needed */
  text-align: center;
}

.link-button:hover {
  background-color: #b7a57a; /* UW Gold */
  color: #4b2e83; /* UW Purple */
}

.link-image {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .links-title {
    font-size: 28px;
  }

  .link-button {
    font-size: 16px;
    max-width: 90%;
  }
}
