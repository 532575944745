.section2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem 0;
  background-color: white;
  max-width: 1200px;
  margin: 0 auto;
}

.prayer-places {
  flex: 1;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prayer-places h1 {
  font-size: 2rem;
  color: #4b2e83;
  margin-bottom: 1.5rem;
  text-align: center;
}

.prayer-places ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.prayer-places ul li {
  font-size: 1.25rem;
  color: #333;
  background-color: #e1e1e1;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 0.5rem;
  text-align: center;
}

.prayer-places ul li a {
  color: inherit;
  text-decoration: none; /* Remove underline */
  font-weight: 500;
  transition: color 0.3s ease;
}

.prayer-places ul li:hover {
  background-color: #d1d1d1;
}

.prayer-places ul li:hover a {
  color: #4b2e83; /* UW purple accent on hover */
}

.prayer-times-container {
  flex: 1;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.prayer-times-container h2 {
  font-size: 1.75rem;
  color: #4b2e83;
  margin-bottom: 1rem;
  text-align: center;
}

.prayer-times-container > * {
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section2 {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }

  .prayer-places,
  .prayer-times-container {
    margin: 0;
    align-items: center;
    text-align: center;
  }

  .prayer-places,
  .prayer-times-container {
    margin-top: 1rem;
  }

  .prayer-times-container {
    margin-top: 2rem;
  }
}
