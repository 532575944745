.section4 {
  text-align: center;
  padding: 20px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.day {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.day h2 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.event {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #eaf4fc;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.event p {
  margin: 3px 0;
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .calendar {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  .day {
    padding: 10px;
  }

  .day h2 {
    font-size: 1em;
  }

  .event p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .calendar {
    grid-template-columns: 1fr;
  }

  .day h2 {
    font-size: 1.1em;
  }

  .day,
  .event {
    padding: 12px;
  }
}
