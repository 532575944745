.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}

.login-container {
  background-color: #4b2e83; /* UW Purple */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.login-container h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

.login-container button {
  background-color: #b7a57a; /* UW Gold */
  color: #4b2e83; /* UW Purple */
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #fff;
}

.error {
  color: #ff4d4d;
  margin-top: 10px;
}
